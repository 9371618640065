import NextImage from 'next/legacy/image';

import { Text } from '@/atoms';

import { toArray } from '@/lib/utils';

import type { TypeChildren, TypeImageComplete } from '@/components/types';
import type { ImageProps } from 'next/legacy/image';
import type { FunctionComponent, HTMLAttributes } from 'react';
import type { TypeButtonsProps, TypeText } from './types';

export const Pretitle: FunctionComponent<TypeChildren> = ({
  children,
}: TypeChildren) => (
  <span className="u-label u-label--overlline block">{children}</span>
);

export const Title: FunctionComponent<
  TypeText & {
    className?: HTMLAttributes<any>['className'];
  }
> = ({
  children,
  as,
  className,
}: TypeText & {
  className?: HTMLAttributes<any>['className'];
}) => (
  <Text className={`u-headline u-headline--h1 ${className ?? ''}`} as={as}>
    {children}
  </Text>
);

export const Description: FunctionComponent<
  TypeChildren & {
    className?: HTMLAttributes<HTMLDivElement>['className'];
  }
> = ({
  children,
  className,
}: TypeChildren & {
  className?: HTMLAttributes<HTMLDivElement>['className'];
}) => <div className={`u-body u-body--m ${className}`}>{children}</div>;

export const Buttons: FunctionComponent<TypeButtonsProps> = ({
  justify = '',
  align = '',
  textAlign = '',
  children,
}: TypeButtonsProps) => (
  <div className={`flex gap-3 ${justify} ${align} ${textAlign}`}>
    {toArray(children).map((child: any, index: number) =>
      child ? <child.type {...child.props} key={index} /> : null,
    )}
  </div>
);

export const Image: FunctionComponent<TypeImageComplete> = ({
  height,
  src,
  width,
  alt,
}) => (
  <NextImage
    src={src}
    width={width as ImageProps['width']}
    height={height as ImageProps['height']}
    alt={alt ?? ''}
  />
);

export const Logo: FunctionComponent<TypeImageComplete> = ({
  height,
  src,
  width,
  alt,
}) => (
  <div className="absolute bottom-5 right-5 w-20 md:bottom-7 md:w-28">
    <NextImage
      src={src}
      width={width as ImageProps['width']}
      height={height as ImageProps['height']}
      alt={alt ?? ''}
    />
  </div>
);
