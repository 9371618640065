import { Picture } from '@/atoms';

import type { TypeImageGraphQL } from '@/lib/graphql/types/basic';

interface TypeHeroSimple {
  desktopImage: TypeImageGraphQL;
  mobileImage: TypeImageGraphQL;
  slideIndex: number;
}

export const HeroSimple = ({
  desktopImage,
  mobileImage,
  slideIndex,
}: TypeHeroSimple) => (
  <Picture
    sources={[
      {
        media: 640,
        src: desktopImage.sourceUrl,
        width: Number(desktopImage.mediaDetails.width),
        height: Number(desktopImage.mediaDetails.height),
        priority: slideIndex === 0,
      },
      {
        src: mobileImage.sourceUrl,
        width: Number(mobileImage.mediaDetails.width),
        height: Number(mobileImage.mediaDetails.height),
        priority: slideIndex === 0,
      },
    ]}
    alt={mobileImage?.altText || 'Imagen de fondo del hero'}
    classNameImg="w-full object-contain"
  />
);
