import { Fragment } from 'react';
import dynamic from 'next/dynamic';
import Image from 'next/image';
import { oneLine } from 'common-tags';

import { PaperTextureRight } from '@/icons';
import { NextLink, Picture } from '@/atoms';
import { Button, Hero } from '@/molecules';

import { containerTransformer } from '@/lib/graphql/transformers';
import { removeBaseURL } from '@/lib/utils';

import type { TypeClassName, WpButton } from '@/components/types';
import type { TypeSrcImageGraphQL } from '@/lib/graphql/types';
import type {
  TypeAlignGraphQL,
  TypeImageGraphQL,
  TypeJustifyGraphQL,
  TypeTextAlignGraphQL,
} from '@/lib/graphql/types/basic';
import type { TypeHeroProps } from '@/molecules/hero';
import type { ImageProps } from 'next/image';

const PaperTextureBottom = dynamic(
  () =>
    import('@/icons/PaperTextureBottom').then((mod) => mod.PaperTextureBottom),
  { ssr: false },
);

const PaperTextureTop = dynamic(
  () => import('@/icons/PaperTextureTop').then((mod) => mod.PaperTextureTop),
  { ssr: false },
);

interface TypeHeroWordpresColumn {
  overlay: TypeHeroProps['overlay'];
  justify: TypeJustifyGraphQL;
  align: TypeAlignGraphQL;
  colorDeFondo: string;
  textAlign: TypeTextAlignGraphQL;
  srcset: TypeSrcImageGraphQL[];
  imagenBase: TypeImageGraphQL;
  negative?: TypeHeroProps['negative'];
  logo: TypeImageGraphQL;
  content: any;
  separadorRasgado: boolean;
  colorSeparador: string;
}

interface TypeHeroWordpres extends TypeClassName {
  columns: TypeHeroWordpresColumn[];
  separadorRasgado: boolean;
  colorSeparador: string;
}

const HeroVariantMinHeight = new Map<string, string>([
  ['default', 'min-h-[490px] md:min-h-[340px]'],
  ['slider', 'min-h-[500px] md:min-h-[488px]'],
  ['marcas', 'min-h-[490px] md:min-h-[340px]'],
  ['categoria', 'min-h-[186px] md:min-h-[232px]'],
  ['dynamic', 'sm:min-h-[340px]'],
]);
const HeroVariantColumnOrder = new Map<string, string>([
  ['default', 'flex-col'],
  ['slider', 'flex-col'],
  ['marcas', 'flex-col-reverse'],
  ['categoria', 'flex-col'],
  ['dynamic', 'flex-col'],
]);

export const HeroComplete = ({
  slide,
  variant,
  slideIndex,
}: {
  slide: TypeHeroWordpres;
  variant: string;
  slideIndex: number;
}) => (
  <div
    className={`${slide.columns.length > 1 ? HeroVariantColumnOrder.get(variant) : ''} relative flex h-full w-full overflow-hidden md:flex-row ${HeroVariantMinHeight.get(variant)}`}
  >
    {slide.columns.map(
      (column: TypeHeroWordpresColumn, columnIndex: number) => {
        const { justify, align, textAlign, backgroundImage, overlay } =
          containerTransformer({ ...column });

        return (
          <div
            key={columnIndex}
            className={`relative flex w-full grow flex-col ${columnIndex === 1 ? 'max-md:min-h-[240px]' : ''}`}
            {...(column.colorDeFondo
              ? {
                  style: {
                    backgroundColor: column.colorDeFondo,
                  },
                }
              : {})}
          >
            {/* Background Images */}
            {backgroundImage && typeof backgroundImage !== 'string' ? (
              backgroundImage?.srcset?.length > 0 ? (
                <Picture
                  sources={[
                    ...backgroundImage.srcset.map((image) => {
                      let media: number | undefined;

                      switch (image.media) {
                        case '4xl':
                          media = 2560;
                          break;
                        case '3xl':
                          media = 1920;
                          break;
                        case '2xl':
                          media = 1440;
                          break;
                        case 'xl':
                          media = 1280;
                          break;
                        case 'lg':
                          media = 1024;
                          break;
                        case 'md':
                          media = 768;
                          break;
                        case 'sm':
                          media = 640;
                          break;
                        case 'xs':
                          media = 360;
                          break;
                      }

                      return {
                        src: image.src,
                        width: Number(image.width),
                        height: Number(image.height),
                        media,
                        priority: columnIndex === 0,
                      };
                    }),
                    {
                      src: backgroundImage.src,
                      width: Number(backgroundImage.width),
                      height: Number(backgroundImage.height),
                      priority: columnIndex === 0,
                    },
                  ]}
                  alt={backgroundImage?.alt ?? ''}
                  sizes="100vw"
                  classNameImg={`inset-0 h-full w-full object-cover object-center ${variant === 'dynamic' ? 'sm:absolute' : 'absolute'}`}
                />
              ) : (
                <Image
                  src={backgroundImage.src}
                  alt={backgroundImage?.alt ?? ''}
                  width={backgroundImage.width as ImageProps['width']}
                  height={backgroundImage.height as ImageProps['height']}
                  draggable={false}
                  priority={slideIndex === 0}
                  className={`inset-0 h-full w-full object-cover object-center ${variant === 'dynamic' ? 'sm:absolute' : 'absolute'}`}
                />
              )
            ) : null}

            {/* Overlay */}
            {overlay && (
              <div
                className="absolute inset-0 bg-black"
                style={{
                  opacity: overlay,
                }}
              />
            )}

            {/* Separador Rasgado */}
            {columnIndex === 1 && slide.separadorRasgado && (
              <Fragment key="paper">
                <PaperTextureRight
                  color={slide.colorSeparador}
                  width={25}
                  className="absolute bottom-0 left-0 top-0 z-10 hidden md:block"
                />

                {variant === 'marcas' ? (
                  <PaperTextureTop
                    color={slide.colorSeparador}
                    height={20}
                    className="absolute bottom-0 left-0 right-0 w-full md:hidden"
                  />
                ) : (
                  <PaperTextureBottom
                    color={slide.colorSeparador}
                    height={20}
                    className="absolute left-0 right-0 top-0 w-full md:hidden"
                  />
                )}
              </Fragment>
            )}

            {/* Content */}
            <div
              className={oneLine`u-wrapper h-full py-10 ${slide.columns.length > 1 ? 'md:max-w-[580px]' : 'max-w-[1160px]'} ${slide.columns.length === 1 ? 'mx-auto' : 'm-0'} ${columnIndex === 0 ? 'self-end' : 'self-start'} ${
                variant === 'dynamic' && slide.columns.length === 1
                  ? 'max-sm:absolute'
                  : ''
              } `}
            >
              <div
                className={`relative flex h-full grow flex-col gap-4 px-4 md:w-full md:max-w-[482px] xl:px-0 ${justify} ${align} ${textAlign} ${
                  column.negative ? 'text-typo-alternative' : ''
                }`}
              >
                {column.content
                  .filter(Boolean)
                  .map((item: any, index: number) => {
                    const type = item.fieldGroupName
                      .split('_')
                      .at(-1)
                      .toLowerCase();

                    switch (type) {
                      case 'pretitle':
                        return (
                          <Hero.Pretitle key={index}>
                            {item.pretitle}
                          </Hero.Pretitle>
                        );
                      case 'title':
                        if (item.mobileTitle) {
                          return (
                            <Fragment key={index}>
                              <Hero.Title
                                className="max-md:hidden"
                                as={item.h1 ? 'h1' : 'span'}
                              >
                                {item.title}
                              </Hero.Title>
                              <Hero.Title className="md:hidden" as="span">
                                {item.mobileTitle}
                              </Hero.Title>
                            </Fragment>
                          );
                        } else {
                          return (
                            <Hero.Title
                              key={index}
                              as={item.h1 ? 'h1' : 'span'}
                            >
                              {item.title}
                            </Hero.Title>
                          );
                        }
                      case 'description':
                        if (item.mobileDescription) {
                          return (
                            <Fragment key={index}>
                              <Hero.Description className="max-md:hidden">
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: item.description,
                                  }}
                                />
                              </Hero.Description>

                              <Hero.Description className="md:hidden">
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: item.mobileDescription,
                                  }}
                                />
                              </Hero.Description>
                            </Fragment>
                          );
                        } else {
                          return (
                            <Hero.Description key={index}>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: item.description,
                                }}
                              />
                            </Hero.Description>
                          );
                        }
                      case 'button':
                        return (
                          <Hero.Buttons key={index}>
                            {item.button?.map(
                              (button: WpButton, index: number) => (
                                <NextLink
                                  href={removeBaseURL(button?.link?.url ?? '#')}
                                  passHref
                                  key={index}
                                >
                                  <Button
                                    as="a"
                                    variant={button.variant}
                                    size={button.size}
                                    negative={button.negativo}
                                  >
                                    {button.label}
                                  </Button>
                                </NextLink>
                              ),
                            )}
                          </Hero.Buttons>
                        );
                      case 'image':
                        return (
                          item.image?.sourceUrl && (
                            <Hero.Image
                              key={index}
                              src={item.image.sourceUrl}
                              width={item.image?.mediaDetails?.width}
                              height={item.image?.mediaDetails?.height}
                              alt={item.image?.altText ?? ''}
                            />
                          )
                        );
                    }
                  })}

                {column.logo && (
                  <Hero.Logo
                    key={columnIndex + 1}
                    src={column.logo.sourceUrl}
                    width={column.logo?.mediaDetails.width}
                    height={column.logo?.mediaDetails.height}
                    alt={column.logo?.altText ?? ''}
                  />
                )}
              </div>
            </div>
          </div>
        );
      },
    )}
  </div>
);
